(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Cancellations
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Cancellations', Cancellations);

  function Cancellations($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/matches/:id/cancellations', {id: '@_id'});
  }
}());
